<template>
  <div>
    <validation-observer ref="soalForm">
      <b-form @submit.prevent="submitForm" @reset="onReset">
        <b-row class="justify-content-between mb-4 align-items-end">
          <b-col cols="auto">
            <a @click="$router.back()" href="#" class="
                text-dark-75
                font-weight-bolder
                text-hover-primary
                mb-1
                font-size-lg
                d-flex
                flex-row
                align-items-center
              ">
              <i class="mdi mdi-chevron-left"></i>
              Kembali
            </a>
          </b-col>
          <b-col cols="auto">
            <button type="submit" class="btn btn-success font-weight-bolder font-size-sm">
              Simpan
            </button>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <div class="card card-custom">
              <div class="card-header border-0 py-5">
                <h3 class="card-title align-items-start flex-column">
                  <span class="card-label font-weight-bolder text-dark">
                    {{ idSoal ? "Ubah" : "Buat" }} Soal
                  </span>
                </h3>
                <div class="card-toolbar">
                  <span @click="modalBankSoal = true" class="btn btn-warning font-weight-bolder font-size-sm"
                    v-if="idUjian">
                    Pilih dari Bank Soal
                  </span>
                </div>
              </div>
              <div class="card-body pt-4">
                <b-form-group id="input-group-1" label="Topik soal:" label-for="input-1">
                  <validation-provider #default="{ errors }" name="Topik Soal" rules="required">
                    <vue-select :state="errors.length > 0 ? false : null" taggable multiple push-tags
                      v-model="currentSoal.topic" :options="topikOptions" />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>

                <b-form-group id="input-group-1" label="Soal:" label-for="input-1">
                  <validation-provider #default="{ errors }" name="Soal" rules="required">
                    <editor v-bind:type="`question`" :key="`soal${keySoal}`" v-if="!idSoal || currentSoal.question"
                      v-model="currentSoal.question" />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
                <b-form-group :id="`input-bobot-${i}`" label="Bobot soal:" label-for="input-1" v-if="type == 'essay'">
                  <validation-provider #default="{ errors }" :name="`Bobot Jawaban ${i}`">
                    <b-form-input :state="errors.length > 0 ? false : null" id="input-1" v-model="currentSoal.point"
                      type="number" placeholder="Masukkan bobot soal"></b-form-input>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
                <b-row class="align-items-start">
                  <b-col>
                    <b-form-group id="input-group-1" label="Tipe soal:" label-for="input-1">
                      <validation-provider #default="{ errors }" name="Tipe Soal" rules="required">
                        <b-form-radio-group :state="errors.length > 0 ? false : null" @input="changeType($event)"
                          required stacked :checked="type" :options="[
        { text: 'Pilihan Ganda', value: 'multiple choice' },
        { text: 'Jawaban Ganda', value: 'multiple answer' },
        { text: 'True/False', value: 'bool' },
        { text: 'Essay', value: 'essay' },
      ]" value-field="value" text-field="text" name="radio-type">
                        </b-form-radio-group>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <!-- <b-col class="d-flex align-items-center" v-if="idUjian">
                    <b-form-group
                      id="input-group-1"
                      label="Bank soal:"
                      label-for="input-1"
                    >
                      <label class="checkbox">
                        <input
                          type="checkbox"
                          ref="addToBank"
                          v-model="currentSoal.add_to_bank"
                        /><span></span>&nbsp; Simpan ke Bank Soal</label
                      >
                    </b-form-group>
                  </b-col> -->
                </b-row>
              </div>
            </div>
          </b-col>
          <b-col v-if="type && type != 'essay'">
            <div class="card card-custom">
              <div class="card-header border-0 py-5">
                <h3 class="card-title align-items-start flex-column">
                  <span class="card-label font-weight-bolder text-dark">
                    Pilihan Jawaban
                  </span>
                </h3>
                <div class="card-toolbar">

                </div>
                <div class="card-body pt-4">
                  <!--          <b-card-text>Header and footers using slots.</b-card-text>-->
                  <b-form-group>
                    <b-form-group :label="`Pilihan ${i + 1}:`" label-for="input-1" v-for="(jawaban, i) in jawabans"
                      :key="`${i}`">
                      <b-row>
                        <b-col cols="auto" class="d-flex flex-column justify-content-between">
                          <label class="checkbox checkbox-lg checkbox-single">
                            <input :key="`${i}${jawaban.isCorrect}`" type="checkbox" v-model="jawaban.isCorrect"
                              @input="changeJawaban(jawaban)" />
                            <span></span>
                          </label>
                          <a :class="`btn btn-icon btn-light-danger btn-sm`" v-if="currentSoal.type != 'bool' && i != 0"
                            @click="deleteJawaban(jawaban, i)">
                            <span :class="`svg-icon`">
                              <!--begin::Svg Icon-->
                              <inline-svg src="/media/svg/icons/General/Trash.svg"></inline-svg>
                              <!--end::Svg Icon-->
                            </span>
                          </a>
                        </b-col>
                        <b-col>
                          <b-form-group :id="`input-bobot-${i}`" label="Bobot Jawaban:" label-for="input-1">
                            <validation-provider #default="{ errors }" :name="`Bobot Jawaban ${i}`">
                              <b-form-input :state="errors.length > 0 ? false : null" id="input-1"
                                v-model="jawaban.point" type="number" step=".1"
                                placeholder="Masukkan bobot jawaban"></b-form-input>
                              <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                          </b-form-group>
                          <validation-provider #default="{ errors }" :name="`Pilihan ${i + 1}`" rules="required"
                            v-if="currentSoal.type != 'bool'">
                            <editor v-bind:type="`question`" :key="`jawaban${jawaban.key}`" v-model="jawaban.answer" />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                          <!--                      <quill-editor-->
                          <!--                          :ref="`soalQuill${jawaban.id2}`"-->
                          <!--                          v-model="jawaban.jawaban"-->
                          <!--                          :options="editorOption"-->
                          <!--                          required-->
                          <!--                          v-if="type==='pg'"-->
                          <!--                      />-->
                          <div v-else v-html="jawaban.answer"></div>
                        </b-col>
                      </b-row>
                    </b-form-group>
                  </b-form-group>
                  <b-row class="justify-content">

                  </b-row>
                  <span @click="jawabans.push({})" class="btn btn-primary font-weight-bolder font-size-sm"
                    v-if="currentSoal.type != 'bool'">
                    Tambah Jawaban</span>
                </div>

              </div>
              <!--          <b-button href="#" variant="primary">Go somewhere</b-button>-->
            </div>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import {
  CLEAR_SOAL,
  CREATE_SOAL,
  GET_ONE_SOAL,
  SET_ONE_SOAL,
  UPDATE_SOAL,
  GET_ONE_QUESTION,
} from "@/core/services/store/soal.module";
import TinyMCE from "@/view/components/TinyMCE";
import {
  CREATE_BANK_SOAL,
  GET_BANK_SOAL,
  GET_ONE_BANK_SOAL,
  UPDATE_BANK_SOAL,
} from "@/core/services/store/banksoal.module";
import Swal from "sweetalert2";
import mixin from "@/core/services/Helpers";

export default {
  name: "SoalForm",
  mixins: [mixin],
  components: {
    editor: TinyMCE,
  },
  computed: {
    ...mapState({
      errors: (state) => state.soal.errors,
    }),
    apiKey() {
      return process.env.VUE_APP_TINY_MCE_API_KEY;
    },
    apiBaseURL() {
      return process.env.VUE_APP_API_BASE_URL;
    },
    // ...mapGetters(["currentSoal"]),
    idSoal() {
      return this.$route.params.idSoal;
    },
    idUjian() {
      return this.$route.params.idUjian;
    },
    idKurikulum() {
      return this.$route.params.idKurikulum;
    },
    vendorId() {
      const user = this.$store.getters.authUser;
      return this.getVendorId(user);
    },
  },
  data() {
    return {
      keySoal: 1,
      isLoading: false,
      isPicked: true,
      jawabanTemp: [],
      modalBankSoal: false,
      type: "pg",
      bankSoal: [],
      editorOption: {
        modules: {},
        readOnly: true,
        theme: "bubble",
      },
      jawabanMultiple: [
        {
          answer: "",
          point: 0,
          isCorrect: true,
        },
      ],
      jawabanPG: [
        {
          answer: "",
          point: 0,
          isCorrect: true,
        },
      ],
      jawabanbool: [
        {
          answer: "True",
          point: 0,
          isCorrect: true,
        },
        {
          answer: "False",
          point: 0,
          isCorrect: false,
        },
      ],
      jawabans: [
        {
          id2: 1,
          jawaban: "",
          isCorrect: true,
        },
      ],
      topikOptions: [],
      currentSoal: {},
      currentPage: 1,
      currentPageBank: 1,
      totalRows: 0,
      totalRowsBank: 0,
      perPage: 10,
      perPageBank: 10,
      pageOptions: [5, 10, 15, { value: 100, text: "Show a lot" }],
      filter: null,
      filterBank: null,
      fields: [
        {
          key: "index",
          label: "No",
          sortable: true,
          sortDirection: "desc",
        },
        {
          key: "kode_soal",
          label: "Kode Soal",
          sortable: true,
        },
        {
          key: "bobot",
          label: "Bobot Soal",
          sortable: true,
        },
        // {
        //   key: 'soal',
        //   label: "Soal",
        //   sortable: true
        // },
        {
          key: "tipe",
          label: "Tipe Soal",
          sortable: true,
        },
        {
          key: "topik",
          label: "Topik Soal",
          sortable: true,
        },
        {
          key: "action",
          label: "Aksi",
          sortable: false,
        },
      ],
      fieldsBank: [
        {
          key: "index",
          label: "No",
          sortable: true,
          sortDirection: "desc",
        },
        {
          key: "kode_soal",
          label: "Kode Soal",
          sortable: true,
        },
        {
          key: "soal",
          label: "Soal",
          sortable: true,
        },
        {
          key: "topik",
          label: "Topik Soal",
          sortable: true,
        },
        {
          key: "action",
          label: "Aksi",
          sortable: false,
        },
      ],
      tipeSoal: {
        pg: "Pilihan Ganda",
        multiple: "Jawaban Ganda",
        bool: "True/False",
        essay: "Essay",
      },
    };
  },
  mounted() {
    this.$store.dispatch(CLEAR_SOAL);

    if (this.idSoal) {
      this.getData();
    } else {
      this.changeType("multiple choice")
    }
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: `${this.idSoal ? "Ubah" : "Buat"} Soal` },
    ]);
  },
  methods: {
    deleteJawaban(jawaban, i) {
      if (this.currentSoal.type == "multiple choice") {
        if (jawaban.isCorrect) {
          this.$set(this.jawabans[0], "isCorrect", true);
        }
      }
      this.$delete(this.jawabans, i);
      for (var j = 0; j < this.jawabans.length; j++) {
        this.$set(this.jawabans[j], "key", this.jawabans[j].key + 1);
      }
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    pilihBankSoal(item) {
      this.currentSoal = item;
      if (this.currentSoal.type == "multiple choice") {
        this.jawabanPG = this.currentSoal.jawabans;
        this.jawabans = item.jawabans;
      } else if (this.currentSoal.type == "multiple answer") {
        this.jawabanMultiple = this.currentSoal.jawabans;
        this.jawabans = item.jawabans;
      } else if (this.currentSoal.type == "bool") {
        this.jawabanbool = this.currentSoal.jawabans;
        this.jawabans = item.jawabans;
      } else if (this.currentSoal.type == "essay") {
        this.jawabans = [];
      }
      this.type = item.tipe;
      // this.jawabans = item.jawabans
      setTimeout(() => {
        for (var i = 0; i < this.jawabans.length; i++) {
          // if (this.jawabans[i].id === this.currentSoal.id_jawaban) {
          //   this.$set(this.jawabans[i], 'isCorrect', true)
          // } else {
          //   this.$set(this.jawabans[i], 'isCorrect', false)
          // }
          this.$set(this.jawabans[i], "key", 0);
          delete this.jawabans[i].id;
        }
        this.keySoal += 1;
        delete this.currentSoal.id;
        delete this.currentSoal.kode_soal;
        delete this.currentSoal.id_jawaban;
        this.modalBankSoal = false;
      }, 1);
    },
    getData() {
      this.isLoading = true;
      this.$store
        .dispatch(GET_ONE_QUESTION, this.idSoal)
        .then((response) => {
          this.currentSoal = response.data;
          setTimeout(() => {
            if (this.currentSoal.type == "multiple choice") {
              this.jawabanPG = this.currentSoal.answer;
              this.jawabans = response.data.answer;
            } else if (this.currentSoal.type == "multiple answer") {
              this.jawabanMultiple = this.currentSoal.answer;
              this.jawabans = response.data.answer;
            } else if (this.currentSoal.type == "bool") {
              this.jawabanbool = this.currentSoal.answer;
            } else if (this.currentSoal.type == "essay") {
              if (this.currentSoal.answer.length > 0) {
                this.currentSoal.point = this.currentSoal.answer[0].point;
              }
              this.jawabans = [];
            }
            for (var i = 0; i < this.jawabans.length; i++) {
              this.$set(this.jawabans[i], "key", 0);
            }
            this.type = response.data.type;
            // this.jawabans = response.data.jawabans
            this.currentSoal.soal = response.data.question;
          }, 1);
          this.isLoading = false;
        })
        .catch(() => {
          this.isLoading = false;
          this.$bvToast.toast(this.errors, {
            title: `Gagal Memuat Soal`,
            variant: "danger",
            solid: true,
          });
        });
    },
    getBankSoal() {
      const param = new URLSearchParams();
      param.append("id_kurikulum", this.idKurikulum);
      this.$store
        .dispatch(GET_BANK_SOAL, param.toString())
        .then((response) => {
          this.bankSoal = response.data;
          this.totalRows = this.bankSoal.length;
          // this.isLoading = false
        })
        .catch(() => {
          // this.isLoading = false
          this.$bvToast.toast(this.errors, {
            title: `Gagal Memuat Soal`,
            variant: "danger",
            solid: true,
          });
        });
    },
    changeJawaban(item) {
      console.log(this.currentSoal.type);
      if (this.currentSoal.type != "multiple answer") {
        for (var i = 0; i < this.jawabans.length; i++) {
          if (this.jawabans[i].isCorrect) {
            this.jawabans[i].point = 0;
            // delete this.jawabans[i].point;
          }
          this.$set(this.jawabans[i], "isCorrect", false);
          // this.currentSoal.jawabans[i].isCorrect = false
        }
        item.isCorrect = true;
      }
      this.isPicked = true;
      this.$store.dispatch(SET_ONE_SOAL, this.currentSoal);
    },
    changeType(value) {
      if (this.currentSoal.type == "multiple choice") {
        this.jawabanPG = this.jawabans;
      } else if (this.currentSoal.type == "multiple answer") {
        this.jawabanMultiple = this.jawabans;
      } else if (this.currentSoal.type == "bool") {
        this.jawabanbool = this.jawabans;
      }

      if (value == "multiple choice") {
        this.jawabans = this.jawabanPG;
      } else if (value == "multiple answer") {
        this.jawabans = this.jawabanMultiple;
      } else if (value == "bool") {
        this.jawabans = this.jawabanbool;
      } else if (value == "essay") {
        this.jawabans = [];
      }
      this.$set(this.currentSoal, "type", value);
      this.type = value;
    },
    submitForm() {
      this.$refs.soalForm.validate().then((success) => {
        if (success) {
          const _data = JSON.parse(JSON.stringify(this.currentSoal));

          _data.answer = this.jawabans;
          _data.id_ujian = this.idUjian;
          _data.type = this.type;

          if (_data.type == "essay") {
            _data.answer = [
              {
                answer: "",
                point: this.currentSoal.point,
                isCorrect: true,
              },
            ];
          }

          console.log(_data);

          if (this.idSoal) {
            _data.id = this.idSoal;
          }

          this.isLoading = true;

          this.$store
            .dispatch(this.idSoal ? UPDATE_SOAL : CREATE_SOAL, _data)
            .then((response) => {
              Swal.fire({
                title: response.message,
                text: `Berhasil ${this.idSoal ? "ubah" : "buat"} soal`,
                icon: "success",
                confirmButtonClass: "btn btn-secondary",
                heightAuto: false,
              });

              this.$router.push(`/bank-soal`);
              this.isLoading = false;
            })
            .catch(() => {
              this.isLoading = false;
              this.$bvToast.toast(this.errors, {
                title: `Gagal Membuat Soal`,
                variant: "danger",
                solid: true,
              });
            });
        }
      });
    },
    onReset() { },
    onEditorBlur(quill) {
      console.log("editor blur!", quill);
    },
    onEditorFocus(quill) {
      console.log("editor focus!", quill);
    },
    onEditorReady(quill) {
      console.log("editor ready!", quill);
    },
    onEditorChange({ quill, html, text }) {
      this.currentUjian.soal = html;
    },
  },
};
</script>

<style scoped></style>
